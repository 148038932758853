import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { useIsMobile } from '@sravni/react-utils';

import type { Application } from '@src/@types/app';
import BottomBannerContainer from '@src/components/BottomBannerContainer';
import { PageContext } from '@src/config';
import { getPageListData } from '@src/config/getPageListData';
import type { IPageContextConfig } from '@src/config/PageContext';
import { BottomBannerTypes } from '@src/constants/bottomBannerTypes';
import { MODES } from '@src/constants/filters';
import { ListKeys } from '@src/constants/microcredits';
import ProductList from '@src/containers/list/ProductList';
import { useBestProducts } from '@src/hooks/useBestProducts';
import { useListPage } from '@src/hooks/useListPage';
import { useLoadRestOffers } from '@src/hooks/useLoadRestOffers';
import { getHasUtmLabel } from '@src/reducers/route';
import { useSelectUser } from '@src/reducers/user';
import { isServer } from '@src/utils/isServer';
import {
  isBezOtkazaPage,
  isBezProtsentovPage,
  isMainPage,
  isNaKartuPage,
  isProverennyeZaymyPage,
} from '@src/utils/routing';
import { Layout as AppLayout } from 'app/Layout';
import { BrandingHeaders } from 'widgets/BrandingHeaders';
import { getRenderCard } from 'widgets/LightweightCard/lib';
import { MobileSubHeader } from 'widgets/MobileSubHeader';
import { SeoComponents } from 'widgets/SeoComponents';

import { useGetHeaderFilters } from '../lib';

import styles from './ListPage.module.scss';

const pageConfig: IPageContextConfig = {
  listKey: ListKeys.LIST,
  source: 'search',
  sortPanelVisible: true,
  seoLinksVisible: true,
  extraFiltersVisible: true,
  cardTermIsVisible: true,
  withBanners: true,
  withInvitationBanner: true,
  withEmailBurner: true,
  withSelectionBanner: true,
  withCardProductLink: true,
  isMainPage: true,
};

export const ListPage: NextPage = () => {
  const isMobile = useIsMobile();
  const { filter, seo, pathname, hasUtmLabel, asPath, query } = useListPage(ListKeys.LIST);
  const user = useSelectUser();
  const config = useMemo(() => ({ ...pageConfig, withMonetizationCardLogoClick: isMobile }), [isMobile]);

  const [pathWithoutQuery] = asPath.split('?');

  useLoadRestOffers(ListKeys.LIST);
  useBestProducts();

  const isMain = isMainPage(asPath, query);
  const isNaKartu = isNaKartuPage(asPath);

  const { Filters, isShowFullDealEntrypoint } = useGetHeaderFilters(filter, seo, user);

  const isShowPromoOnCard = !isProverennyeZaymyPage(asPath) && !isBezProtsentovPage(asPath);
  const isOldVitrin = isBezOtkazaPage(asPath) || isBezProtsentovPage(asPath);

  return (
    <AppLayout>
      <PageContext.Provider value={config}>
        <BrandingHeaders containerClassName={styles.page_header} afterHeaders={Filters} />

        {isMobile && !isOldVitrin && <MobileSubHeader />}

        <ProductList
          className={styles.product_list}
          gridLayout={!isOldVitrin}
          filter={filter}
          pathname={pathname}
          renderCard={(props) =>
            getRenderCard(props, pathWithoutQuery, seo, isMobile, hasUtmLabel, isShowPromoOnCard, isOldVitrin)
          }
        />

        {seo && (
          <SeoComponents
            seo={seo}
            breadcrumbs={seo.breadcrumbs}
            prefooter={seo.prefooter}
            {...(isOldVitrin
              ? {
                  showAnchorLinks: isShowFullDealEntrypoint && !isMain,
                  showSubheader: !isNaKartu,
                }
              : {
                  showAnchorLinks: false,
                  showSubheader: false,
                })}
          />
        )}
        <BottomBannerContainer enabledTypes={[BottomBannerTypes.OUT_OF_PAGE, BottomBannerTypes.SIDEBAR]} />
      </PageContext.Provider>
    </AppLayout>
  );
};

ListPage.getInitialProps = (ctx: Application.ReduxNextPageContext) => {
  if (isServer) return {};

  const hasUtmLabel = getHasUtmLabel(ctx.store.getState());

  return getPageListData(ctx, {
    key: ListKeys.LIST,
    defaultFiltersMode: hasUtmLabel ? MODES.AS_UTM : MODES.DEFAULT,
  });
};
